.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.imgLogin {
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .home{ */
  /* width: 100%; */
/* } */

.imgAdmin {
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cardAdmin:hover{
  transform: rotate(5deg);
  cursor:pointer;
}
.cardAdmin:active{
  transform: rotate(5deg) scale(0.9);
  cursor:pointer;
}

/* .divPrendas{ */
  /* width: 400px; */
/* } */

.btnPrendas{
  width: 80%;
  text-align: start;
}
.btnPrendas span{
  float: right;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

